var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ewm page-sub-box"},[_c('div',{staticClass:"locker_setting_list sub_new_style01 sub_ui_box1"},[_c('div',{staticClass:"page_search_box line_bottom_1px pb-0 mb-0"},[_c('DxValidationGroup',{ref:"searchValidationGroup"},[_c('div',{staticClass:"flex space-x-2 inner"},[_c('div',{staticClass:"pt-2"},[_vm._v("기준년")]),_c('DxDateBox',{attrs:{"styling-mode":"outlined","width":100,"type":"date","date-serialization-format":"yyyyMMdd","display-format":"yyyy","date-out-of-range-message":"시작일은 종료일보다 작거나 같아야 합니다.","maxLength":"4","invalid-date-message":"입력 데이터가 유효하지 않습니다.","calendar-options":{
                zoomLevel: 'decade',
                minZoomLevel: 'decade',
                maxZoomLevel: 'decade',
              }},model:{value:(_vm.searchType.customTypes.vacationYear),callback:function ($$v) {_vm.$set(_vm.searchType.customTypes, "vacationYear", $$v)},expression:"searchType.customTypes.vacationYear"}},[_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":"기준년은 필수입니다."}})],1)],1),_c('DxButton',{staticClass:"btn_M box-btn-search",attrs:{"text":"검색","type":"button","height":30},on:{"click":_vm.selectDataList}})],1)])],1),_c('div',{staticClass:"pt-2 content-info-box01 space-x-6"},[_c('span',{staticClass:"text-lg"},[_vm._v("기준연차갯수: "+_vm._s(_vm.config.vacationDayBase))]),_c('span',{staticClass:"text-lg"},[_vm._v("연차추가 범위(입사년차기준): "+_vm._s(_vm.config.vacationDayRange))])])]),_c('esp-dx-data-grid',{ref:_vm.dataGrid.refName,attrs:{"data-grid":_vm.dataGrid},on:{"saving":_vm.onSave}}),_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.initData ? _vm.modal.initData.title : null,"min-width":_vm.modal.initData ? _vm.modal.initData.width : null,"width":_vm.modal.initData ? _vm.modal.initData.width : null,"min-height":_vm.modal.initData ? _vm.modal.initData.height : null,"height":_vm.modal.initData ? _vm.modal.initData.height : null,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.modal.isOpened},on:{"hiding":function($event){return _vm.isOpenModal(false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c(_vm.modal.currentComponent,{tag:"component",attrs:{"contentData":_vm.modal.contentData},model:{value:(_vm.modal.contentData),callback:function ($$v) {_vm.$set(_vm.modal, "contentData", $$v)},expression:"modal.contentData"}})],1)]},proxy:true}]),model:{value:(_vm.modal.isOpened),callback:function ($$v) {_vm.$set(_vm.modal, "isOpened", $$v)},expression:"modal.isOpened"}},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":_vm.modal.initData.hasOwnProperty('buttons')
            ? _vm.modal.initData.buttons.hasOwnProperty('cancel')
              ? _vm.modal.initData.buttons.hasOwnProperty('cancel')
              : !_vm.modal.initData.buttons.hasOwnProperty('cancel')
            : false,"options":{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: _vm.modal.initData.hasOwnProperty('buttons')
            ? _vm.modal.initData.buttons.hasOwnProperty('cancel')
              ? _vm.modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            _vm.isOpenModal(false);
          },
        }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }